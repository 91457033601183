<template>
    <router-view/>
</template>


<script>
    export default {
        name: 'LocalPage',
        beforeCreate() {
            // Redirect from /:lang/ to dashboard.
            if (this.$route.name === 'language-router') {
                if (this.$store.state.token) {
                    this.$router.push({'name': 'dashboard', 'params': {'lang': this.$store.state.language}})
                } else {
                    this.$router.push({'name': 'login'})
                }
            }
        }
    }
</script>
